export const INITIAL_STATE_BOX = {
  category_name: [],
  participants: [],
  price: {
    min_price: 0,
    max_price: 0,
  },
  province: [],
};
export const INITIAL_STATE_ACTIVITIES = {
  price: {
    min_price: 0,
    max_price: 0,
  },
  has_online_reservation: false,
  category_name: [],
  subcategory_name: [],
  participants: [],
  province: [],
};
export const INITIAL_STATE_TRAVEL = {
  price: {
    min_price: 0,
    max_price: 0,
  },
  months: [],
  province: [],
};
export const INITIAL_STATE_TRAVEL_UY = {
  price: {
    min_price: 0,
    max_price: 0,
  },
  has_online_reservation: false,
  participants: [],
  province: [],
};
