import { defineStore } from 'pinia';
import apolloClient from 'utils/apolloClient';

// import PaginatedBoxes from 'assets/graphql/queries/PaginatedBoxes.graphql';
// import PaginatedActivities from 'assets/graphql/queries/PaginatedActivities.graphql';
// import PaginatedTravelActivities from 'assets/graphql/queries/travel/PaginatedTravelActivities.graphql';

import ElasticPaginatedBoxes from 'assets/graphql/queries/ElasticPaginatedBoxes.graphql';
import ElasticPaginatedActivities from 'assets/graphql/queries/ElasticPaginatedActivities.graphql';
import ElasticPaginatedTravelActivities from 'assets/graphql/queries/travel/ElasticPaginatedTravelActivities.graphql';
import ElasticPaginatedTravelUyActivities from 'assets/graphql/queries/ElasticPaginatedTravelUyActivities.graphql';

import {
  INITIAL_STATE_BOX,
  INITIAL_STATE_ACTIVITIES,
  INITIAL_STATE_TRAVEL,
  INITIAL_STATE_TRAVEL_UY,
} from 'constants/initialState';
import keywordTranslate from 'utils/keywordTranslate';

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    currentFilters: [
      { ...INITIAL_STATE_BOX },
      { ...INITIAL_STATE_ACTIVITIES },
      { ...INITIAL_STATE_TRAVEL },
      { ...INITIAL_STATE_TRAVEL_UY },
    ],
    facets: [],
    results: [],
    filtersForSend: {},
    page_info: {
      number: 1,
    },
    allResultsCount: { 0: 0, 1: 0, 2: 0 },
    loading: false,
    optionCards: [
      {
        name: 'box',
        title: 'Regalos con opciones',
        description: `${keywordTranslate(
          'Regalá',
        )} un <b>conjunto de experiencias</b>, tu agasajado elige cuál vive.`,
      },
      {
        name: 'activity',
        title: 'Actividades',
        description: `${keywordTranslate('Regalá')} o ${keywordTranslate(
          'disfrutá',
        )} de <b>una actividad en especial.</b>`,
      },
      {
        name: 'travel',
        title: 'Escapadas en Argentina',
        description: `${keywordTranslate(
          'Disfrutá',
        )} de <b>alojamientos y experiencias</b> en los mejores destinos.`,
      },
      {
        name: 'travelUy',
        title: 'Viajes',
        description: `${keywordTranslate(
          'Disfrutá',
        )} de <b>alojamientos y experiencias</b> en los mejores destinos.`,
      },
    ],
    cardSelected: 0,
    orderBy: 'relevance',
  }),
  getters: {
    getFacets: ({ facets }) => facets,
    getCurrentFilters: ({ currentFilters }) => currentFilters,
    getCurrentTags: ({ currentFilters, facets }) => {
      let tags = [];
      for (const filter of currentFilters) {
        for (const [key, value] of Object.entries(filter)) {
          if (value.length || value === true) {
            const facet = facets.find(facet => facet.name === key);
            const { input_type: inputType, results } = facet || {};

            if (inputType === 'toggle') {
              tags = [
                ...tags,
                {
                  key,
                  value: facet.name,
                  inputType,
                  showName: facet.show_name,
                },
              ];
            } else if (inputType === 'radio') {
              const result = results.find(res => res.value === value);
              if (result) {
                tags = [
                  ...tags,
                  {
                    key,
                    value,
                    inputType,
                    showName: result.show_name,
                  },
                ];
              }
            } else if (results && results.length) {
              for (const res of results) {
                if (value.includes(res.value)) {
                  tags = [
                    ...tags,
                    {
                      key,
                      value: res.value,
                      inputType,
                      showName: res.show_name,
                    },
                  ];
                }
              }
            }
          }
        }
      }
      return tags;
    },
    getAllResultsCount: ({ allResultsCount }) => allResultsCount,
    getFiltersType: ({ filtersType }) => filtersType,
    getOptionCards: ({ optionCards }) => optionCards,
    getCardSelected: ({ cardSelected }) => cardSelected,
    getPageInfo: ({ page_info }) => page_info,
    getResults: ({ results }) => results,
    getLoading: ({ loading }) => loading,
    getCurrentOrder: ({ orderBy }) => orderBy,
  },
  actions: {
    getQuery(index = this.cardSelected) {
      return [
        ElasticPaginatedBoxes,
        ElasticPaginatedActivities,
        ElasticPaginatedTravelActivities,
        ElasticPaginatedTravelUyActivities,
      ][index];
    },
    getVariablesForQuery() {
      return {
        filters: JSON.stringify(this.filtersForSend),
        order_by: this.orderBy,
        page_number: this.page_info.number,
        per_page: 12,
      };
    },
    getPaginatedName(index = this.cardSelected) {
      return [
        'elastic_paginated_boxes',
        'elastic_paginated_activities',
        'elastic_paginated_travel_activities',
        'elastic_paginated_travel_activities_uy',
      ][index];
    },
    parsePrice() {
      const price = 'price';
      const { min_price, max_price } =
        this.currentFilters[this.cardSelected][price];
      if (min_price === 0 && max_price === 0) {
        this.filtersForSend[price] = [];
      } else {
        this.filtersForSend[price] = {
          from: min_price,
          to: max_price,
        };
      }
    },
    deleteTags({ key, inputType, value }) {
      if (inputType === 'radio' || inputType === 'toggle') {
        this.currentFilters[this.cardSelected][key] = [];
        this.filtersForSend[key] = [];
        this.getDataFromFilters();
        return;
      }
      if (inputType === 'checkbox') {
        this.currentFilters[this.cardSelected][key].splice(
          this.currentFilters[this.cardSelected][key].indexOf(value),
          1,
        );
        this.handleChangeFilters(key, inputType);
        return;
      }
    },
    parseFiltersForSend(key, type) {
      this.parsePrice();
      if (type === 'checkbox') {
        this.filtersForSend[key] = this.currentFilters[this.cardSelected][key];
      }
      if (type === 'toggle' || type === 'radio') {
        this.filtersForSend[key] = [
          this.currentFilters[this.cardSelected][key].toString(),
        ];
      }
    },
    async handleChangeFilters(key, type) {
      this.resetPaginationNumber();
      if (type === 'orderBy') {
        this.orderBy = key;
      } else {
        this.parseFiltersForSend(key, type);
      }

      try {
        this.loading = true;
        const { data } = await apolloClient.query({
          query: this.getQuery(),
          variables: this.getVariablesForQuery(),
        });
        this.results = data[this.getPaginatedName()].results;
        this.facets = data[this.getPaginatedName()].facets;
        this.page_info = data[this.getPaginatedName()].page_info;
        this.allResultsCount[this.cardSelected] = this.page_info.count;

        for (const facet of this.facets) {
          if (facet.name.includes('subfilter_')) {
            this.currentFilters[this.cardSelected][facet.name] =
              this.currentFilters[this.cardSelected][facet.name] ?? [];
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        return e;
      }
    },
    setKeywords(keywords) {
      this.filtersForSend = {
        ...this.filtersForSend,
        keywords,
      };
    },
    async setAllResults() {
      for (const el in this.allResultsCount) {
        if (Number(el) !== this.cardSelected) {
          const { data } = await apolloClient.query({
            query: this.getQuery(el),
            variables: this.getVariablesForQuery(),
          });
          this.allResultsCount[el] =
            data[this.getPaginatedName(el)].page_info.count;
        }
      }
    },
    async setBoxCategory(category) {
      this.currentFilters[this.getCardSelected].category_name = category;
      await this.handleChangeFilters('category_name', 'checkbox');
    },
    getNameHasOnlineReservation() {
      return 'has_online_reservation';
    },
    async getDataFromFilters() {
      this.loading = true;
      try {
        const { data } = await apolloClient.query({
          query: this.getQuery(),
          variables: this.getVariablesForQuery(),
        });
        this.results = data[this.getPaginatedName()].results;
        this.facets = data[this.getPaginatedName()].facets;
        this.page_info = data[this.getPaginatedName()].page_info;
        this.filtersForSend = {
          ...this.currentFilters[this.cardSelected],
          [this.getNameHasOnlineReservation()]: [],
          keywords: this.filtersForSend.keywords,
        };

        this.parsePrice();
        this.allResultsCount[this.cardSelected] = this.page_info.count;
        this.setAllResults();
        this.loading = false;
      } catch (e) {
        this.loading = false;
        return e;
      }
    },
    clearCurrentFilters(keywords = '') {
      this.currentFilters = [
        { ...INITIAL_STATE_BOX },
        { ...INITIAL_STATE_ACTIVITIES },
        { ...INITIAL_STATE_TRAVEL },
        { ...INITIAL_STATE_TRAVEL_UY },
      ];
      this.resetPaginationNumber();
      this.filtersForSend = {
        keywords,
      };
      this.orderBy = 'relevance';
      this.getDataFromFilters();
    },
    resetPaginationNumber() {
      this.page_info.number = 1;
    },
    setCard(cardId) {
      this.clearCurrentFilters(this.filtersForSend.keywords);
      this.cardSelected = cardId;
      this.resetPaginationNumber();
      this.getDataFromFilters();
    },
  },
});
